import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  createMuiTheme,
  Divider,
  TextField,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import { blueGrey, red } from "@material-ui/core/colors";
import { CheckBox } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAppState } from "../../components/AppProvider/AppProvider";
import { intlFormat, makeDateStringUTC } from "../../helpers/helpers";
import {
  fetchApps,
  fetchBanks,
  fetchHrEmployees,
  fetchPph21MarriageCategories,
  fetchUser,
  fetchUserApps,
} from "../../helpers/fetchers";
import filterNotHiddenAndSort from "../../helpers/filterNotHiddenAndSort";
import {
  initialApp,
  initialDepartment,
  initialPph21,
  initialUser,
  initialUserApp,
} from "../../helpers/modelinitials";
import { RequestStatus } from "../../helpers/RequestStatus";
import { blueTheme } from "../../helpers/themes";
import {
  App,
  AuthorizationLevel,
  Bank,
  Department,
  ExtHrEmployee,
  Role,
  User,
  UserApp,
  UserPostBody,
} from "../../model";
import Select from "react-select";
import {
  AuthserverPph21MarriageCategories,
  AuthserverPph21MarriageCategory,
  AuthserverUser_AuthorizationLevel,
  authserverUser_AuthorizationLevelFromJSON,
  authserverUser_AuthorizationLevelToJSON,
} from "../../masterbigsystem";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { render } from "react-dom";

const UserDetail = () => {
  const { id }: { id: string } = useParams();
  const history = useHistory();
  const [user, setUser] = useState<User>({ ...initialUser });
  const [userApps, setUserApps] = useState<UserApp[]>([]);
  const [apps, setApps] = useState<App[]>([]);
  const [ctx, dispatch] = useAppState() ?? [];
  const [requestStatus, setRequestStatus] = useState<RequestStatus>("NotAsked");
  const [changePassword, setChangePassword] = useState(false);
  const [banks, setBanks] = useState<Bank[]>([]);
  const [extHrEmployees, setExtHrEmployees] = useState<ExtHrEmployee[]>([]);

  const terminationTimestamp = useRef(null as string | null);

  // Password for new user
  const [passwordConfirm, setPasswordConfirm] = useState("");

  // New password for update user
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

  const [, refresh] = useState(false);

  const render = () => {
    refresh((n) => !n);
  };

  const pph21Settings = useRef(
    AuthserverPph21MarriageCategories.fromPartial({ categories: [] })
  );

  const fetchPphData = async () => {
    const d = await fetchPph21MarriageCategories(ctx?.apiKey ?? "");

    if (d) {
      pph21Settings.current = d;

      render();
    }
  };

  useEffect(() => {
    fetchPphData();

    if (!isNaN(parseInt(id))) {
      (async () => {
        setRequestStatus("Loading");

        const [user, userApps, apps, banks, hrEmployees] = await Promise.all([
          fetchUser(parseInt(id) ?? 0, ctx?.baseUrl ?? "", ctx?.apiKey ?? ""),

          fetchUserApps(
            parseInt(id) ?? 0,
            ctx?.baseUrl ?? "",
            ctx?.apiKey ?? ""
          ),
          fetchApps(ctx?.baseUrl ?? "", ctx?.apiKey ?? ""),
          fetchBanks({
            baseUrl: ctx?.baseUrl ?? "",
            apiKey: ctx?.apiKey ?? "",
          }),
          fetchHrEmployees(ctx?.baseUrl ?? "", ctx?.apiKey ?? ""),
        ]);

        if (user) setUser(user);
        setUserApps(userApps);
        setApps(apps);
        setBanks(banks);
        setExtHrEmployees(hrEmployees);

        setRequestStatus("Success");
      })();
    }
  }, []);

  const handleSave = async () => {
    try {
      const response = await fetch(`${ctx?.baseUrl}/users/save`, {
        method: "post",
        headers: {
          authorization: ctx?.apiKey ?? "",
          "content-type": "application/json",
        },
        body: JSON.stringify({
          user: {
            ...user,
            department:
              user.departmentId !== 0
                ? { ...initialDepartment, id: user.departmentId }
                : null,
          },
          changePassword: changePassword,
          newPassword: newPassword,
          userEnteredPassword: user.password,
        } as UserPostBody),
      });

      if (response.status !== 201) throw await "Error saving user";

      const userResp = (await response.json()) as User;

      // Save user apps
      await Promise.all(
        userApps.map(async (userApp) => {
          try {
            await fetch(`${ctx?.baseUrl}/userapps`, {
              method: "post",
              headers: {
                authorization: ctx?.apiKey ?? "",
                "content-type": "application/json",
              },
              body: JSON.stringify({
                ...userApp,
                user: userResp,
              }),
            });
          } catch (e) {
            console.error(e);
          }
        })
      );

      history.push("/");
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <ThemeProvider theme={blueTheme}>
        <Box m={3}>
          <Box display="flex" flexWrap={1}>
            <Typography variant="h5">User Detail {id}</Typography>
            <Box mx={1}>
              <Button
                disabled={
                  user && user.id !== 0
                    ? changePassword &&
                      (newPassword === "" || newPassword !== newPasswordConfirm) // If user exists, check password change & empty
                    : user.username === "" ||
                      user.password === "" ||
                      user.password !== passwordConfirm // If user does not exist, change empty pasword & username
                }
                onClick={handleSave}
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </Box>
            {requestStatus === "Loading" ? (
              <CircularProgress disableShrink size={25} />
            ) : (
              <></>
            )}
          </Box>

          <Box display="flex" my={1} flexWrap="wrap">
            {/* <Box flexGrow={1} mx={1}>
              <TextField
                onChange={(e) => setUser({ ...user, name: e.target.value })}
                value={user.name}
                label="Name"
                fullWidth
              />
            </Box> */}
            <Box flexGrow={1} mx={1}>
              <TextField
                onChange={(e) => setUser({ ...user, username: e.target.value })}
                value={user.username}
                label="Username"
                fullWidth
              />
            </Box>
            <Box flexGrow={1} mx={1}>
              <TextField
                onChange={(e) => setUser({ ...user, email: e.target.value })}
                value={user.email}
                label="Email"
                fullWidth
              />
            </Box>
            <Box flexGrow={1} mx={1}>
              <Box display="flex" alignItems="end">
                <Box>+</Box>
                <Box mx={1}>
                  <TextField
                    onChange={(e) =>
                      setUser({ ...user, countryCode: e.target.value })
                    }
                    value={user.countryCode}
                    label="Code"
                    style={{ width: 50 }}
                  />
                </Box>

                {/* <TextField
                  onChange={(e) => setUser({ ...user, phone: e.target.value })}
                  value={user.phone}
                  label="Phone"
                  fullWidth
                /> */}
              </Box>
            </Box>
          </Box>

          <Box display="flex" my={2} flexWrap="wrap">
            {/* <Box flexGrow={1}>
              <TextField
                size="small"
                variant="outlined"
                onChange={(e) =>
                  setUser({ ...user, addressLong: e.target.value })
                }
                value={user.addressLong}
                label="Address"
                fullWidth
                multiline
              />
            </Box> */}
            <Box flexGrow={1}>
              <TextField
                size="small"
                variant="outlined"
                onChange={(e) => {
                  setUser({ ...user, bankAccountNumber: e.target.value });
                }}
                value={user.bankAccountNumber}
                label="Bank account number"
                fullWidth
                multiline
              />
            </Box>
            <Box flexGrow={1}>
              <Autocomplete
                options={banks.sort(
                  (a, b) => a?.code?.localeCompare(b?.code ?? "") ?? 0
                )}
                onChange={(_, bank) => {
                  setUser({ ...user, bank: bank as Bank });
                }}
                value={user.bank}
                getOptionLabel={(bank) => `${bank?.name} (${bank.code})` ?? ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    variant="outlined"
                    label="Bank"
                    fullWidth
                    multiline
                  />
                )}
              />
            </Box>

            <Box></Box>
          </Box>

          <Box display="flex" my={2} flexWrap="wrap">
            {/* {user.departmentId} */}
            {/* {JSON.stringify(ctx?.departments.find((department) => department.id === user.departmentId))} */}
            <Autocomplete
              options={ctx?.departments ?? []}
              getOptionLabel={(dept) => dept.name}
              value={
                ctx?.departments.find(
                  (department) => department.id === user.departmentId
                ) ?? null
              }
              onChange={(e, dept) => {
                console.log("Dept:", dept);
                setUser({
                  ...user,
                  departmentId: (dept as Department)?.id ?? 0,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Department"
                  variant="outlined"
                  size="small"
                  style={{ width: 300 }}
                />
              )}
            />

            <Box display="flex" alignItems="center" ml={2}>
              <Typography>Is Head?</Typography>

              <Checkbox
                checked={user.isHead ?? false}
                onClick={() => setUser({ ...user, isHead: !user.isHead })}
              />
            </Box>

            <Box display="flex" alignItems="center" ml={2}>
              <Typography>Job Entry Allowed?</Typography>

              <Checkbox
                checked={user.jobEntryAllowed ?? false}
                onClick={() =>
                  setUser({ ...user, jobEntryAllowed: !user.jobEntryAllowed })
                }
              />
            </Box>

            <Box display="flex" alignItems="center" ml={2}>
              <Typography>Subcon?</Typography>

              <Checkbox
                checked={user.isSubcon ?? false}
                onClick={() => setUser({ ...user, isSubcon: !user.isSubcon })}
              />
            </Box>

            <Box display="flex" alignItems="center" ml={2}>
              <Typography>Exclude Glob. Attendance?</Typography>

              <Checkbox
                checked={user.excludeInGlobalAttendance ?? false}
                onClick={() =>
                  setUser({
                    ...user,
                    excludeInGlobalAttendance: !user.excludeInGlobalAttendance,
                  })
                }
              />
            </Box>
          </Box>

          <div>
            <small>Authorization Level</small>
          </div>
          <div className="d-flex">
            <div>
              <Select
                placeholder="Auth level"
                options={[
                  AuthserverUser_AuthorizationLevel.DIRECTOR,
                  AuthserverUser_AuthorizationLevel.MANAGER,
                  AuthserverUser_AuthorizationLevel.SUPERVISOR,
                  AuthserverUser_AuthorizationLevel.TEAM_LEAD,
                  AuthserverUser_AuthorizationLevel.OPERATOR,
                ].map((s) => ({
                  label: authserverUser_AuthorizationLevelToJSON(s),
                  value: s,
                }))}
                onChange={(v) => {
                  setUser({
                    ...user,
                    authorizationLevel: v?.value
                      ? (authserverUser_AuthorizationLevelToJSON(
                          v.value
                        ) as AuthorizationLevel)
                      : user.authorizationLevel,
                  });
                }}
              />
            </div>

            <div className="mx-2">{user.authorizationLevel}</div>
          </div>

          <Box display="flex" my={2} flexWrap="wrap" alignItems="center">
            <Autocomplete
              options={ctx?.roles ?? []}
              getOptionLabel={(role) => role.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Roles"
                  variant="outlined"
                  size="small"
                  style={{ width: 300 }}
                />
              )}
              onChange={(e, role) => {
                console.log(role);

                if (role)
                  setUser({
                    ...user,
                    roles: user.roles.find(
                      (rolex) => rolex.id === (role as Role)?.id
                    )
                      ? user.roles
                      : (role as Role)
                      ? [...user.roles, role as Role]
                      : user.roles,
                  });
              }}
            />
            <Box display="flex" flexWrap="wrap " mx={2}>
              <Typography>Selected:</Typography>
              {user.roles.map((role) => (
                <Box
                  mx={1}
                  px={1}
                  style={{
                    cursor: "pointer",
                    color: "white",
                    backgroundColor: blueGrey[500],
                  }}
                  borderRadius={5}
                  onClick={() => {
                    setUser({
                      ...user,
                      roles: user.roles.filter((rolex) => rolex.id !== role.id),
                    });
                  }}
                >
                  <Typography style={{ fontWeight: "bold" }}>
                    {role.name}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>

          <Box display="flex" alignItems="center" flexWrap="wrap">
            <Autocomplete
              options={apps}
              getOptionLabel={(app) => app.name}
              onChange={(e, app) => {
                if (
                  filterNotHiddenAndSort(userApps).find(
                    (userApp) => userApp.app?.id === (app as App)?.id
                  )
                ) {
                  // If app found, skip
                } else {
                  if (app)
                    setUserApps([
                      ...userApps,
                      { ...initialUserApp, user: user, app: app as App },
                    ]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Apps Whitelist"
                  variant="outlined"
                  size="small"
                  style={{ width: 300 }}
                />
              )}
            />

            <Box mx={2}>
              <Typography>Selected:</Typography>
            </Box>

            {filterNotHiddenAndSort(userApps).map((userApp, i) => (
              <>
                <Box
                  mx={1}
                  px={1}
                  style={{
                    cursor: "pointer",
                    color: "white",
                    backgroundColor: blueGrey[500],
                  }}
                  borderRadius={5}
                  onClick={() => {
                    const newUserApps = [...userApps];

                    let foundUserApp = filterNotHiddenAndSort(newUserApps).find(
                      (_, ix) => i === ix
                    );

                    console.log("new user apps:", newUserApps, foundUserApp);

                    if (foundUserApp) {
                      foundUserApp.hidden = true;
                      setUserApps(newUserApps);
                    }
                  }}
                >
                  <Typography style={{ fontWeight: "bold" }}>
                    {userApp.app?.name}
                  </Typography>
                </Box>
              </>
            ))}
          </Box>

          <Box my={2} display="flex" flexWrap={1}>
            <TextField
              label="Device Unique ID"
              variant="outlined"
              size="small"
              value={user.deviceUniqueId ?? ""}
              onChange={(e) => {
                setUser({ ...user, deviceUniqueId: e.target.value ?? "" });
              }}
            />
            <Box mx={1} flexGrow={1}>
              <Autocomplete
                options={extHrEmployees ?? []}
                getOptionLabel={(e) =>
                  `${e.firstName ?? ""}${
                    e.middleName ? ` ${e.middleName}` : ""
                  }${e.lastName ? ` ${e.lastName}` : ""}`
                }
                onChange={(_, employee) => {
                  if (employee) {
                    setUser({
                      ...user,
                      hrId: (employee as ExtHrEmployee).id,
                    });
                  } else {
                    setUser({
                      ...user,
                      hrId: null,
                    });
                  }
                }}
                value={
                  extHrEmployees.find(
                    (extHrEmployee) => extHrEmployee.id === user.hrId
                  ) ?? null
                }
                size="small"
                renderInput={(params) => (
                  <TextField
                    label={`HR Employee`}
                    variant="outlined"
                    {...params}
                  />
                )}
              />
            </Box>
          </Box>
          {user.hrId ? (
            <Box>
              {(() => {
                const foundUser = extHrEmployees.find(
                  (extUserEmployee) => extUserEmployee.id === user.hrId
                );

                return foundUser ? (
                  <>
                    <Box>
                      <strong>Name: </strong> {foundUser.firstName}
                      {foundUser.middleName ? ` ${foundUser.middleName}` : ""}
                      {foundUser.lastName ? ` ${foundUser.lastName}` : ""}
                    </Box>
                    <Box>
                      <strong>Address: </strong>
                      {foundUser.location?.address}
                    </Box>
                    <Box>
                      <strong>Phone: </strong>
                      {foundUser?.contact?.find((c) => c.hp && c.hp !== "")?.hp}
                    </Box>
                  </>
                ) : (
                  <></>
                );
              })()}
            </Box>
          ) : (
            <></>
          )}
          {/* {JSON.stringify(
            extHrEmployees.find(
              (extHrEmployee) => extHrEmployee.id === user.hrId
            )
          )} */}

          {/* <Box overflow="auto">
            <Typography variant="caption">
              <pre>{JSON.stringify(userApps, null, 2)}</pre>
            </Typography>
          </Box> */}

          {user.id && user.id !== 0 ? (
            <>
              <Box display="flex" alignItems="center">
                <Box>
                  <Typography>Change password?</Typography>
                </Box>
                <Box>
                  <Checkbox
                    checked={changePassword}
                    onClick={() => setChangePassword(!changePassword)}
                  />
                </Box>
              </Box>
              <Box display="flex" alignItems="end">
                {changePassword ? (
                  <>
                    <Box>
                      <TextField
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        label="New Password"
                        type="password"
                        multiline
                      />
                    </Box>

                    <Box mx={1}>
                      <TextField
                        value={newPasswordConfirm}
                        onChange={(e) => setNewPasswordConfirm(e.target.value)}
                        label="Confirm Password"
                        type="password"
                        multiline
                      />
                    </Box>

                    {newPassword !== newPasswordConfirm ? (
                      <Typography color="error">
                        Passwords do not match!
                      </Typography>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </Box>
            </>
          ) : (
            <>
              <Box display="flex" alignItems="end" flexWrap="wrap">
                <Box>
                  <TextField
                    onChange={(e) =>
                      setUser({ ...user, password: e.target.value })
                    }
                    value={user.password}
                    label="Password"
                    type="password"
                    multiline
                  />
                </Box>

                <Box mx={1}>
                  <TextField
                    onChange={(e) => setPasswordConfirm(e.target.value)}
                    value={passwordConfirm}
                    label="Confirm Password"
                    type="password"
                    multiline
                  />
                </Box>

                {user.password !== passwordConfirm ? (
                  <Typography color="error">Passwords do not match!</Typography>
                ) : (
                  <></>
                )}
              </Box>
            </>
          )}

          <Divider />

          <Box my={1} py={1} display="flex">
            <Box>
              <TextField
                type="date"
                variant="outlined"
                size="small"
                label="COVID vaccine 1 date"
                InputLabelProps={{ shrink: true }}
                value={
                  user.vaccine1
                    ? makeDateStringUTC(new Date(user.vaccine1))
                    : null
                }
                onChange={(e) => {
                  if (e.target.value && e.target.value !== "")
                    setUser({
                      ...user,
                      vaccine1: `${e.target.value}T00:00:00Z`,
                    });
                }}
              />
            </Box>
            <Box>
              <Box display="flex" justifyContent="center" mx={1}>
                <Typography>Done?</Typography>{" "}
              </Box>
              <Box display="flex" justifyContent="center">
                <Checkbox
                  checked={user.vaccine1Done ?? false}
                  onClick={(e) => {
                    setUser({
                      ...user,
                      vaccine1Done: !(user.vaccine1Done ?? false),
                    });
                  }}
                />
              </Box>
            </Box>
            <Box mx={2}>
              <TextField
                type="date"
                variant="outlined"
                size="small"
                label="COVID vaccine 2 date"
                InputLabelProps={{ shrink: true }}
                value={
                  user.vaccine2
                    ? makeDateStringUTC(new Date(user.vaccine2))
                    : null
                }
                onChange={(e) => {
                  if (e.target.value && e.target.value !== "")
                    setUser({
                      ...user,
                      vaccine2: `${e.target.value}T00:00:00Z`,
                    });
                }}
              />
            </Box>
            <Box>
              <Box display="flex" justifyContent="center" mx={1}>
                <Typography>Done?</Typography>{" "}
              </Box>
              <Box display="flex" justifyContent="center">
                <Checkbox
                  checked={user.vaccine2Done ?? false}
                  onClick={(e) => {
                    setUser({
                      ...user,
                      vaccine2Done: !(user.vaccine2Done ?? false),
                    });
                  }}
                />
              </Box>
            </Box>
          </Box>

          <div>
            <strong>Entry Date</strong>
          </div>

          <Box className="my-2">
            <TextField
              type="date"
              variant="outlined"
              size="small"
              label="Entry date"
              InputLabelProps={{ shrink: true }}
              value={
                user.entryDate
                  ? makeDateStringUTC(new Date(user.entryDate))
                  : null
              }
              onChange={(e) => {
                if (e.target.value && e.target.value !== "")
                  setUser({
                    ...user,
                    entryDate: `${e.target.value}T00:00:00Z`,
                  });
              }}
            />
          </Box>

          <div>
            <strong>PPH Status</strong>
          </div>
          <div>
            <div>
              <Select
                placeholder="PPH 21 setting..."
                options={pph21Settings.current.categories.map((c) => ({
                  label: c.name,
                  value: c,
                }))}
                value={pph21Settings.current.categories
                  .map((c) => ({
                    label: c.name,
                    value: c,
                  }))
                  .find(
                    (c) =>
                      `${c.value.masterJavaBaseModel?.id}` ===
                      `${user.pph21MarriageCategory?.id}`
                  )}
                onChange={(v) => {
                  const val = v as { value: AuthserverPph21MarriageCategory };
                  setUser({
                    ...user,
                    pph21MarriageCategory: {
                      ...initialPph21,
                      id: val.value.masterJavaBaseModel?.id
                        ? isNaN(parseInt(val.value.masterJavaBaseModel?.id))
                          ? 0
                          : parseInt(val.value.masterJavaBaseModel?.id)
                        : 0,
                    },
                  });
                }}
              />
            </div>
          </div>

          <Box my={2}>
            <Divider />
          </Box>

          <hr className="border border-dark" />

          <div>
            <h4>Employee home</h4>
          </div>

          {/* <div>
            <input
              className="form-control form-control-sm"
              onBlur={() => {}}
              placeholder="Search location name..."
            />
          </div> */}

          <div className="d-flex">
            <div className="flex-grow-1">
              <div>
                <div>Lat</div>
                <div>
                  <input
                    className="form-control form-control-sm"
                    onBlur={(e) => {
                      setUser({
                        ...user,
                        lat: isNaN(parseFloat(e.target.value))
                          ? user.lat
                          : parseFloat(e.target.value),
                      });
                    }}
                    placeholder="Lat..."
                  />
                </div>
                <div>{user.lat}</div>
              </div>
            </div>
            <div className="flex-grow-1">
              <div>
                <div>Lon</div>
                <div>
                  {" "}
                  <input
                    className="form-control form-control-sm"
                    onBlur={(e) => {
                      setUser({
                        ...user,
                        lon: isNaN(parseFloat(e.target.value))
                          ? user.lon
                          : parseFloat(e.target.value),
                      });
                    }}
                    placeholder="Lon..."
                  />
                  {user.lon}
                </div>
              </div>
            </div>
          </div>

          <div style={{ height: "35vh", width: "100%" }}>
            <MapContainer
              center={[user.lat ?? -6.227026, user.lon ?? 106.816581]}
              zoom={11}
              scrollWheelZoom={false}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker
                position={[user.lat ?? -6.227026, user.lon ?? 106.816581]}
              >
                <Popup>Location of {user.username}.</Popup>
              </Marker>
            </MapContainer>
          </div>

          <Box>
            <Typography variant="h5">Termination</Typography>
          </Box>

          <Box>
            {user?.hidden ? (
              <>
                <div className="d-flex">
                  <Button
                    onClick={async () => {
                      const confirmation = window.confirm(`Undo termination?`);

                      if (confirmation) {
                        const resp = await fetch(
                          `${ctx?.baseUrl}/users/${user.id}/show`,
                          {
                            method: "post",
                            headers: { authorization: ctx?.apiKey ?? "" },
                          }
                        );

                        alert("Employee redone successfully.");
                        history.push("/");
                      } else {
                        alert("Employee not re-opened.");
                      }
                    }}
                    color="primary"
                    variant="contained"
                  >
                    Undo
                  </Button>
                  {user.terminationTimestamp ? (
                    <>
                      {(() => {
                        try {
                          return intlFormat({
                            date: user.terminationTimestamp,
                            dateStyle: "long",
                            timeStyle: "medium",
                          });
                        } catch (e) {
                          return "";
                        }
                      })()}
                    </>
                  ) : (
                    <> </>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="d-flex">
                  <Button
                    onClick={async () => {
                      const prompt = window.prompt(
                        `Really terminate employee ${user.name}? Please write down the employee's full name or username (case sensitive).`
                      );

                      if (
                        prompt &&
                        prompt !== "" &&
                        (prompt === user.name || prompt === user.username)
                      ) {
                        console.log(`${terminationTimestamp.current}T00:00:00`);
                        const resp = await fetch(
                          `${ctx?.baseUrl}/users/${
                            user.id
                          }/hide?terminationTimestamp=${
                            terminationTimestamp.current ?? ""
                          }`,
                          {
                            method: "delete",
                            headers: { authorization: ctx?.apiKey ?? "" },
                          }
                        );

                        alert("Employee terminated successfully.");
                        history.push("/");
                      } else {
                        alert("Employee not terminated.");
                      }
                    }}
                    color="secondary"
                    variant="contained"
                  >
                    Terminate
                  </Button>
                  <input
                    style={{ width: 150 }}
                    type="date"
                    className="form-control form-control-sm"
                    defaultValue={terminationTimestamp.current ?? undefined}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        return;
                      }

                      terminationTimestamp.current = new Date(
                        e.target.value
                      ).toISOString();
                      render();
                    }}
                  />
                </div>
              </>
            )}
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default UserDetail;
