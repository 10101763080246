import {
  Box,
  Button,
  CircularProgress,
  createMuiTheme,
  makeStyles,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
  Typography,
  useEventCallback,
} from "@material-ui/core";
import { blue, blueGrey, green, red, yellow } from "@material-ui/core/colors";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppState } from "../../components/AppProvider/AppProvider";
import { fetchAllUserApps, fetchUsers } from "../../helpers/fetchers";
import filterNotHiddenAndSort, {
  filterNotHidden,
} from "../../helpers/filterNotHiddenAndSort";
import {
  makeDateStringUTC,
  makeReadableDateString,
} from "../../helpers/helpers";
import { RequestStatus } from "../../helpers/RequestStatus";
import { blueTheme } from "../../helpers/themes";
import {
  AuthserverUserWhatsappSendMessage,
  AuthserverUserWhatsappSendMessageType,
  authserverUserWhatsappSendMessageTypeToJSON,
} from "../../masterbigsystem";
import { BaseModel, User, UserApp } from "../../model";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    borderWidth: 1,
    borderColor: blueGrey[200],
    borderStyle: "solid",
  },
}));

const Users = () => {
  const classes = useStyles();

  const [searchInput, setSearchInput] = useState("");
  const [ctx, dispatch] = useAppState() ?? [];
  const [users, setUsers] = useState<User[]>([]);
  const [userApps, setUserApps] = useState<UserApp[]>([]);
  const [showHidden, setShowHidden] = useState(false);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>("NotAsked");

  useEffect(() => {
    fetchData();
  }, [showHidden]);

  const fetchData = async () => {
    setRequestStatus("Loading");

    console.log("BAseURL:", ctx?.baseUrl, "Api key:", ctx?.apiKey);

    const [users, userApps] = await Promise.all([
      fetchUsers(ctx?.baseUrl ?? "", ctx?.apiKey ?? "", {
        showHidden: showHidden,
      }),
      fetchAllUserApps(ctx?.baseUrl ?? "", ctx?.apiKey ?? ""),
    ]);

    setUsers(users);
    setUserApps(userApps);
    setRequestStatus("Success");
  };

  return (
    <>
      <ThemeProvider theme={blueTheme}>
        <Box m={3}>
          <Box display="flex" alignItems="center">
            <Typography variant="h5">Users</Typography>
            {requestStatus === "Loading" ? (
              <CircularProgress disableShrink size={20} />
            ) : (
              <></>
            )}
          </Box>

          <Box display="flex" alignItems="center" my={1}>
            <Link to="/users/new">
              <Button variant="contained" color="primary">
                Add
              </Button>
            </Link>

            <TextField
              label="Search"
              variant="outlined"
              size="small"
              fullWidth
              placeholder="Search (by phone, username, email, etc...)"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />

            <Box
              mx={2}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography>Show terminated?</Typography>
              <Switch
                checked={showHidden}
                onClick={() => {
                  setShowHidden(!showHidden);
                }}
              />
            </Box>
          </Box>
          <Box
            overflow="auto"
            border={1}
            borderColor={blueGrey[300]}
            boxShadow={5}
            borderRadius={5}
            style={{ height: "72vh" }}
            my={2}
          >
            <Paper>
              <Table style={{ borderCollapse: "separate" }} size="small">
                <TableHead>
                  <TableRow>
                    {[
                      "Name",
                      `Phone`,
                      `WhatsApp`,
                      "Username",
                      "Marriage",
                      "Bank acc",
                      "Entry date",
                      "Excl G.Attend",
                      "Subcon",
                      "Email",
                      "Department",
                      "Roles",
                      "Apps Whitelist",
                      "iOS user?",
                      "iOS UDID",
                      "Device Unique ID",
                      "Pending ID",
                    ].map((head, i) => (
                      <TableCell
                        align="center"
                        style={{
                          position: "sticky",
                          top: 0,
                          left: i === 0 ? 0 : "",
                          zIndex: i === 0 ? 2 : 0,
                          backgroundColor: blueGrey[500],
                          color: "white",
                          borderWidth: 1,
                          borderColor: blueGrey[200],
                          borderStyle: "solid",
                        }}
                      >
                        {head.toLowerCase().includes("phone") ? (
                          <>
                            <div>
                              <div className="d-flex">
                                <div>
                                  Phone (
                                  {users.filter(
                                    (u) => u.phoneHr && u.phoneHr !== ""
                                  ).length ?? 0}
                                  )
                                </div>
                                <div>
                                  {/* <button
                                    className="btn btn-sm btn-primary"
                                    onClick={async () => {
                                      const confirmed = window.confirm(
                                        "Really send template to all users?"
                                      );

                                      if (confirmed) {
                                        await fetch(
                                          `${process.env.REACT_APP_BASE_URL}/send-multi-whatsapp`,
                                          {
                                            method: "post",
                                            headers: {
                                              "content-type":
                                                "application/json",
                                              authorization: ctx?.apiKey ?? "",
                                            },
                                            body: JSON.stringify(
                                              AuthserverUserWhatsappSendMessage.fromPartial(
                                                {
                                                  userIds: users
                                                    .filter(
                                                      (u) =>
                                                        u.id &&
                                                        u.phoneHr &&
                                                        u.phoneHr !== ""
                                                    )
                                                    .map((u) => `${u.id}`),
                                                  templateName: "hello_gspe",
                                                  type: AuthserverUserWhatsappSendMessageType.TEMPLATE,
                                                }
                                              )
                                            ),
                                          }
                                        );
                                      }
                                    }}
                                  >
                                    Template
                                  </button> */}
                                  <button
                                    className="btn btn-sm btn-success"
                                    onClick={async () => {
                                      try {
                                        const msg = window.prompt(
                                          "Enter broadcast message:"
                                        );
                                        try {
                                          if (msg && msg !== "") {
                                            await fetch(
                                              `${process.env.REACT_APP_BASE_URL}/send-multi-whatsapp`,
                                              {
                                                method: "post",
                                                headers: {
                                                  "content-type":
                                                    "application/json",
                                                  authorization:
                                                    ctx?.apiKey ?? "",
                                                },
                                                body: JSON.stringify(
                                                  AuthserverUserWhatsappSendMessage.fromPartial(
                                                    {
                                                      userIds: users
                                                        .filter(
                                                          (u) =>
                                                            u.id &&
                                                            u.phoneHr &&
                                                            u.phoneHr !== ""
                                                        )
                                                        .map((u) => `${u.id}`),
                                                      message: msg,
                                                      type: AuthserverUserWhatsappSendMessageType.MESSAGE,
                                                    }
                                                  )
                                                ),
                                              }
                                            );
                                          }
                                        } catch (e) {}
                                      } catch (e) {}
                                    }}
                                  >
                                    Broadcast
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>{head}</>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users
                    .filter((user) =>
                      `${user.name}${user.username}${user.countryCode}${user.phone}${user.email}`
                        .toLowerCase()
                        .includes(searchInput.toLowerCase())
                    )
                    .map((user) => {
                      return (
                        <TableRow>
                          <TableCell
                            className={`${classes.tableCell}`}
                            style={{
                              color: blue[600],
                              position: "sticky",
                              backgroundColor: user?.hidden
                                ? "lightsalmon"
                                : "white",
                              left: 0,
                              zIndex: 1,
                            }}
                            align="center"
                          >
                            <Box>
                              <Link to={`/users/${user.id}`}>
                                {user.name && user.name !== ""
                                  ? user.name
                                  : "(No name)"}
                              </Link>{" "}
                            </Box>
                            <Box>
                              <strong
                                style={{
                                  color: user.hrId ? green[600] : red[600],
                                }}
                              >
                                {user.hrId ? "HR Mapped" : "No HR mapped"}
                              </strong>
                            </Box>
                          </TableCell>
                          {/* <TableCell
                            className={classes.tableCell}
                            style={{
                              backgroundColor: user.vaccine1
                                ? user.vaccine1Done
                                  ? green[500]
                                  : yellow[500]
                                : red[500],
                            }}
                            align="center"
                          >
                            <strong>
                              {user.vaccine1
                                ? makeReadableDateString(
                                    new Date(
                                      makeDateStringUTC(new Date(user.vaccine1))
                                    )
                                  )
                                : ""}
                            </strong>
                          </TableCell> */}
                          <TableCell
                            className={classes.tableCell}
                            align="center"
                          >
                            {user.phoneHr}
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            align="center"
                          >
                            {user.phoneHr && user.phoneHr !== "" ? (
                              <>
                                <div className="d-flex flex-column justify-content-start align-items-start">
                                  {/* <div>
                                    <button
                                      className="btn btn-sm btn-primary"
                                      onClick={async () => {
                                        try {
                                          const confirmed = window.confirm(
                                            "Really send template to user?"
                                          );
                                          if (confirmed) {
                                            await fetch(
                                              `${process.env.REACT_APP_BASE_URL}/send-multi-whatsapp`,
                                              {
                                                method: "post",
                                                headers: {
                                                  "content-type":
                                                    "application/json",
                                                  authorization:
                                                    ctx?.apiKey ?? "",
                                                },
                                                body: JSON.stringify(
                                                  AuthserverUserWhatsappSendMessage.fromPartial(
                                                    {
                                                      userIds: [
                                                        user.id
                                                          ? `${user.id}`
                                                          : "",
                                                      ],
                                                      templateName:
                                                        "hello_gspe",
                                                      type: AuthserverUserWhatsappSendMessageType.TEMPLATE,
                                                    }
                                                  )
                                                ),
                                              }
                                            );
                                          }
                                        } catch (e) {}
                                      }}
                                    >
                                      Template
                                    </button>
                                  </div> */}
                                  <div>
                                    <button
                                      className="btn btn-sm btn-success"
                                      onClick={async () => {
                                        try {
                                          const msg =
                                            window.prompt("Enter message:");

                                          if (msg && msg !== "") {
                                            await fetch(
                                              `${process.env.REACT_APP_BASE_URL}/send-multi-whatsapp`,
                                              {
                                                method: "post",
                                                headers: {
                                                  "content-type":
                                                    "application/json",
                                                  authorization:
                                                    ctx?.apiKey ?? "",
                                                },
                                                body: JSON.stringify(
                                                  AuthserverUserWhatsappSendMessage.fromPartial(
                                                    {
                                                      userIds: [
                                                        user.id
                                                          ? `${user.id}`
                                                          : "",
                                                      ],
                                                      message: msg,
                                                      type: AuthserverUserWhatsappSendMessageType.MESSAGE,
                                                    }
                                                  )
                                                ),
                                              }
                                            );
                                          }
                                        } catch (e) {}
                                      }}
                                    >
                                      Msg
                                    </button>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            align="center"
                          >
                            {user.username}
                          </TableCell>
                          <TableCell
                            className={`${classes.tableCell} ${
                              user.pph21MarriageCategory?.id ? `` : `bg-danger`
                            }`}
                            align="center"
                          >
                            {user.pph21MarriageCategory?.name}
                          </TableCell>
                          <TableCell
                            className={`${classes.tableCell} ${
                              user.bankAccountNumber &&
                              user.bankAccountNumber !== ""
                                ? ``
                                : `bg-danger`
                            }`}
                            align="center"
                          >
                            {user.bankAccountNumber}
                          </TableCell>
                          <TableCell
                            className={`${classes.tableCell} ${
                              user.entryDate &&
                              new Date().getTime() >=
                                new Date(
                                  new Date(
                                    user.entryDate?.split("T")?.[0]
                                  ).getFullYear(),
                                  new Date(
                                    user.entryDate?.split("T")?.[0]
                                  ).getMonth() + 3,
                                  new Date(
                                    user.entryDate?.split("T")?.[0]
                                  ).getDate()
                                ).getTime()
                                ? ``
                                : `bg-warning`
                            }`}
                            align="center"
                          >
                            {user.entryDate?.split("T")?.[0]}
                          </TableCell>

                          <TableCell
                            className={classes.tableCell}
                            align="center"
                          >
                            <input
                              type="checkbox"
                              checked={user.excludeInGlobalAttendance ?? false}
                            />
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            align="center"
                          >
                            <input
                              type="checkbox"
                              checked={user.isSubcon ?? false}
                            />
                          </TableCell>

                          {/* <TableCell
                            className={classes.tableCell}
                            align="center"
                          >
                            {user.countryCode && user.countryCode !== ""
                              ? `+${user.countryCode}`
                              : ""}
                            {user.phone}
                          </TableCell> */}
                          <TableCell
                            className={classes.tableCell}
                            align="center"
                          >
                            {user.email}
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            align="center"
                          >
                            {user.departmentName}
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            align="center"
                          >
                            {user.roles.map((role) => role.name).join(", ")}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {filterNotHidden(userApps)
                              .filter((userApp) => userApp.user?.id === user.id)
                              .map((userApp) => userApp.app?.name)
                              .join(", ")}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {user.iosUser ? "Yes" : "No"}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {user.iosUdid ?? ""}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {user.deviceUniqueId ?? ""}
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            style={{
                              backgroundColor:
                                user.deviceUniqueIdApproval &&
                                user.deviceUniqueIdApproval !== "" &&
                                user.deviceUniqueIdApproval !==
                                  user.deviceUniqueId
                                  ? "yellow"
                                  : "",
                            }}
                          >
                            {user.deviceUniqueIdApproval ?? ""}
                            {user.deviceUniqueIdApproval &&
                            user.deviceUniqueIdApproval !== "" &&
                            user.deviceUniqueIdApproval !==
                              user.deviceUniqueId ? (
                              <>
                                <Box>
                                  <Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    onClick={async (e) => {
                                      const confirmation =
                                        window.confirm("Really approve?");

                                      if (confirmation) {
                                        try {
                                          const resp = await fetch(
                                            `${ctx?.baseUrl}/users/${user.id}/approve-pending-id`,
                                            {
                                              method: "post",
                                              headers: {
                                                "content-type":
                                                  "application/json",
                                                authorization:
                                                  ctx?.apiKey ?? "",
                                              },
                                              body: JSON.stringify({
                                                deviceUniqueId:
                                                  user.deviceUniqueId ?? null,
                                              }),
                                            }
                                          );

                                          if (resp.status !== 200)
                                            throw await resp.text();
                                          fetchData();
                                        } catch (e) {
                                          console.error(e);
                                        }
                                      }
                                    }}
                                  >
                                    Approve
                                  </Button>
                                </Box>
                              </>
                            ) : (
                              <></>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </Paper>
          </Box>

          {/* <Box my={3}>
          <pre>Debug:</pre>
          <Typography variant="caption">
            <pre>{JSON.stringify(users, null, 2)}</pre>
          </Typography>
        </Box> */}
        </Box>
      </ThemeProvider>
    </>
  );
};

export default Users;
