export const makeDateStringUTC = (date: Date) => {
  const y = date.getUTCFullYear();
  const m =
    date.getUTCMonth() + 1 < 10
      ? `0${date.getUTCMonth() + 1}`
      : `${date.getUTCMonth() + 1}`;
  const d =
    date.getUTCDate() + 1 < 10
      ? `0${date.getUTCDate()}`
      : `${date.getUTCDate()}`;

  return `${y}-${m}-${d}`;
};
export const makeDateString = (date: Date) => {
  const y = date.getFullYear();
  const m =
    date.getMonth() + 1 < 10
      ? `0${date.getMonth() + 1}`
      : `${date.getMonth() + 1}`;
  const d = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;

  return `${y}-${m}-${d}`;
};

export const makeReadableDateString = (date: Date) =>
  Intl.DateTimeFormat(navigator.language ?? "en-US", {
    dateStyle: "medium",
  } as any).format(date);

export const intlFormat = (params?: {
  date?: string;
  dateStyle?: "medium" | "full" | "long" | "short" | undefined;
  timeStyle?: "medium" | "full" | "long" | "short" | undefined;
}) => {
  try {
    return Intl.DateTimeFormat("en-US", {
      dateStyle: params?.dateStyle,
      timeStyle: params?.timeStyle,
    }).format(new Date(params?.date ?? ""));
  } catch (e) {
    return "";
  }
};
