import { AuthserverPph21MarriageCategories, AuthserverPph21MarriageCategory, AuthserverShiftTemplate, AuthserverShiftTemplates, AuthserverUserShifts } from "../masterbigsystem";
import {
  App,
  Bank,
  Department,
  ExtHrEmployee,
  Role,
  User,
  UserApp,
} from "../model";

export const fetchUsers = async (
  baseUrl: string,
  apiKey: string,
  opts?: { showHidden?: boolean }
) => {
  try {
    return (await (
      await fetch(
        `${baseUrl}/users/view?showHidden=${opts?.showHidden ?? false}`,
        {
          headers: {
            authorization: apiKey ?? "",
          },
        }
      )
    ).json()) as User[];
  } catch (e) {
    return [];
  }
};

export const fetchShifts = async (apiKey: string) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/employeeshifts`,
      {
        headers: {
          authorization: apiKey ?? "",
        },
      }
    );

    return AuthserverUserShifts.fromPartial(await resp.json());
  } catch (e) {
    return AuthserverUserShifts.fromPartial({});
  }
};
export const fetchShiftTemplates = async (apiKey: string) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/employeeshifttemplates`,
      {
        headers: {
          authorization: apiKey ?? "",
        },
      }
    );

    return AuthserverShiftTemplates.fromPartial(await resp.json());
  } catch (e) {
    return AuthserverShiftTemplates.fromPartial({});
  }
};

export const fetchPph21MarriageCategories = async (apiKey: string) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/pph21marriagecategories-proto`,
      {
        headers: {
          authorization: apiKey ?? "",
        },
      }
    );

    return AuthserverPph21MarriageCategories.fromPartial(await resp.json());
  } catch (e) {
    return AuthserverPph21MarriageCategories.fromPartial({});
  }
};


export const fetchUser = async (
  id: number,
  baseUrl: string,
  apiKey: string
) => {
  try {
    return (await (
      await fetch(`${baseUrl}/users/${id}/view`, {
        headers: {
          authorization: apiKey ?? "",
        },
      })
    ).json()) as User;
  } catch (e) {
    return null;
  }
};

export const fetchHrEmployees = async (baseUrl: string, apiKey: string) => {
  try {
    console.log("HR URL", baseUrl);
    return (await (
      await fetch(`${baseUrl}/ext-hr-employees`, {
        headers: {
          authorization: apiKey ?? "",
        },
      })
    ).json()) as ExtHrEmployee[];
  } catch (e) {
    return [];
  }
};

export const fetchBanks = async (props: {
  baseUrl?: string;
  apiKey?: string;
}) => {
  try {
    return (await (
      await fetch(`${props?.baseUrl}/banks`, {
        headers: {
          authorization: props?.apiKey ?? "",
        },
      })
    ).json()) as Bank[];
  } catch (e) {
    return [];
  }
};

export const fetchUserApps = async (
  id: number,
  baseUrl: string,
  apiKey: string
) => {
  try {
    return (await (
      await fetch(`${baseUrl}/users/${id}/apps`, {
        headers: {
          authorization: apiKey ?? "",
        },
      })
    ).json()) as UserApp[];
  } catch (e) {
    return [];
  }
};

export const fetchAllUserApps = async (baseUrl: string, apiKey: string) => {
  try {
    return (await (
      await fetch(`${baseUrl}/userapps`, {
        headers: {
          authorization: apiKey ?? "",
        },
      })
    ).json()) as UserApp[];
  } catch (e) {
    return [];
  }
};

export const fetchInitialData = async (baseUrl: string, apiKey: string) => {
  const [departments, roles] = await Promise.all([
    fetchDepartments(baseUrl, apiKey),
    fetchRoles(baseUrl, apiKey),
  ]);

  return {
    departments: departments,
    roles: roles,
  };
};

export const fetchDepartments = async (baseUrl: string, apiKey: string) => {
  try {
    return (await (
      await fetch(`${baseUrl}/departments`, {
        headers: { authorization: apiKey },
      })
    ).json()) as Department[];
  } catch (e) {
    return [];
  }
};

export const fetchRoles = async (baseUrl: string, apiKey: string) => {
  try {
    return (await (
      await fetch(`${baseUrl}/roles`, { headers: { authorization: apiKey } })
    ).json()) as Role[];
  } catch (e) {
    return [];
  }
};

export const fetchApps = async (baseUrl: string, apiKey: string) => {
  try {
    return (await (
      await fetch(`${baseUrl}/apps`, { headers: { authorization: apiKey } })
    ).json()) as App[];
  } catch (e) {
    return [];
  }
};

export const fetchApp = async (id: number, baseUrl: string, apiKey: string) => {
  try {
    return (await (
      await fetch(`${baseUrl}/apps/${id}`, {
        headers: { authorization: apiKey },
      })
    ).json()) as App;
  } catch (e) {
    return null;
  }
};
